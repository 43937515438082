// NOTE: Vue3 recommends to not use mixins anymore
// https://vuejs.org/api/options-composition.html#mixins
// refactor when we can
// used in forms/Viewpoint.vue

import _ from 'lodash';
export default {
  methods: {
    isEmpty: function(e) {
      return _.isEmpty(Object.values(e).filter(Boolean));
    }
  }
};
