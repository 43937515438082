<template>
  <div>
    <q-dialog v-model="showDialog" full-height>
      <q-card style="width: 700px; max-width: 80vw;">
        <q-bar class="text-white bg-green"
          >Viewpoint
          <q-space />
          <q-btn
            icon="keyboard_arrow_left"
            @click="$emit('prev')"
            flat
            round
            color="text-white"
          />
          {{ index + 1 }} of {{ count }}
          <q-btn
            icon="keyboard_arrow_right"
            flat
            @click="$emit('next')"
            round
            color="text-white"
          />

          <q-space />
          <q-btn v-close-popup dense round flat icon="close" />
        </q-bar>

        <q-scroll-area>
          <q-card-section>
            <h3>{{ collection.title }}</h3>
            <p><strong>{{ viewpoint.title }}</strong></p>
            <p><i>{{ viewpoint.introduction }}</i></p>
            <div class="imagehack">
            <span v-html="viewpoint.text" />
            </div>
          </q-card-section>
        </q-scroll-area>
      </q-card>
    </q-dialog>
  </div>
</template>
<style >
.imagehack img {
  max-width: 100%
}
</style>
<script>
export default {
  name: 'ViewCard',
  props: {
    collection: {
      type: Object,
      default: () => {}
    },
    viewpoint: {
      type: Object,
      default: () => {}
    },
    show: {
      type: Boolean,
      default: false
    },
    count: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    }
  },
  computed: {
    showDialog: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('close');
        return val;
      }
    }
  }
};
</script>
