<template>
  <!-- Viewpoint FORM -->
  <div>
    <section class="bg-grey-4">
      <q-card style="width:560px">
        <q-bar
          ><b>{{ action }} Viewpoint</b>
          <q-space />
          <q-btn v-close-popup dense round flat icon="close" />
        </q-bar>

        <q-card-section>
          <q-form class="q-gutter-md q-pa-md">
            <q-input filled v-model="vp.title" label="Title for index card" />
            <q-input
              filled
              type="textarea"
              v-model="vp.introduction"
              label="Short text on index card"
              min-height="5rem"
            />
            <quill-editor
              ref="editor"
              v-model="vp.text"
              placeholder="Text for viewpoint"
              :toolbar-options="[
                [{ 'header': [ 3, 4, 5, false] }],
                ['bold', 'italic', 'underline'],
                ['blockquote',{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ]"
            />
          </q-form>
        </q-card-section>

        <q-card-actions class="q-pa-xl" align="center">
          <q-btn no-caps :disabled="false" v-close-popup label="Cancel" />
          <q-btn
            no-caps
            color="green"
            :disabled="isEmpty(vp)"
            label="Save"
            @click="save"
          />
        </q-card-actions>
      </q-card>
    </section>
  </div>
</template>
<script>
import { API } from '@/tic';
import isEmpty from '@/mixins/isEmpty.mixin.js';
import QuillEditor from '@/components/QuillEditor';

export default {
  name: 'ViewpointForm',
  props: {
    collection: {
      type: Object,
      default: () => {}
    },
    record: {
      type: Object,
      default: () => {}
    },
    action: {
      type: String,
      default: ''
    }
  },

  components: { QuillEditor },
  mixins: [isEmpty],

  mounted() {
    console.log('VIEWPOINTFORM');
    if (this.record) {
      this.vp = { ...this.record };
    }
  },
  data() {
    return {
      vp: {
        title: '',
        introduction: '',
        text: ''
      }
    };
  },
  methods: {
    save() {
      console.log('save vp ', this.vp.uuid);
      if (this.action === 'Add') {
        API.post('/viewpoints/addtoCollection', {
          collection: this.collection.uuid,
          vp: this.vp
        }).then(() => {
          console.log('VP added to collection ', this.collection.uuid);
        });
        this.$emit('reload', false);
        this.$q.notify({ message: 'Viewpoint Added', position: 'center' });
      } else if (this.action === 'Edit') {
        console.log('save changes for vp');
        API.post('/viewpoints/vpsave', {
          vp: this.vp
        }).then(() => {
          this.$emit('reload', false);
          this.$q.notify({ message: 'Viewpoint Changed', position: 'center' });
        });
      }
    }
  }
};
</script>
