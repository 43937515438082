<template>
  <!--
    view a collection of viewpoints
    /viewpoints/<slug>
-->
  <div ref="viewpointindex">
    <section id="buttonbar" v-if="isAdmin">
      <div class="row q-mx-auto">
        <div class="col q-py-md">
          <a class="subnavlink" @click="addViewpoint">
            <i class="fa fa-plus-large q-pr-sm" aria-hidden="true" />
            Add a Viewpoint
          </a>
        </div>
      </div>
      <q-dialog
        v-model="toggleForm"
        persisent
        transition-show="slide-up"
        transition-hide="slide-down"
      >
        <viewpoint-form
          :action="action"
          :collection="collection"
          :record="record"
          @reload="reload"
        />
      </q-dialog>
    </section>

    <section>
      <div class="row q-mx-auto q-py-md justify-between items-start">
        <div class="col">
          <q-img
            cover
            v-if="collection.iconic"
            style="max-width: 240px; height: 180px"
            class="float-left on-left"
            :src="collection.iconic"
          />
          <h4>{{ collection.title }}</h4>
          <p v-html="newline(collection.text)" />
        </div>
      </div>
    </section>

    <!-- contains the modal view of the Viewpoint card-->
    <viewCard
      @prev="prev"
      @next="next"
      @close="show = false"
      :index="index"
      :count="collection.vps ? collection.vps.length : 0"
      :show="show"
      :collection="collection"
      :viewpoint="record"
      :action="action"
      :record="record"
      :delete="remove"
    />

    <section id="card-holder">
      <div class="row q-mx-auto justify-start items-start">
        <div class="row q-gutter-md">
          <span :key="vp.uuid" v-for="(vp, i) in collection.vps" :id="vp.uuid">
            <Card
              :show-ordering="true"
              @left="left(vp, index)"
              @right="right(vp, index)"
              type="Viewpoint"
              csstype="ViewpointCollection"
              @cardClicked="viewCard(vp, i)"
              :title="vp.title"
              :btn-edit-modal="user === collection.owner"
              @showEdit="editCard(vp)"
              :btn-delete="user === collection.owner"
              @deleteCard="remove(vp.uuid)"
              :showOrdering="user === collection.owner"
              >{{ vp.introduction }}
            </Card>
          </span>
        </div>
      </div>

      <div class="row flex-center" v-if="isChanged">
        <q-btn
          no-caps
          color="primary"
          label="Save changes"
          @click="saveOrderChange"
        />
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { API } from "@/tic";
import ViewpointForm from "@/forms/Viewpoint";
import Card from "../tags/Card";
import viewCard from "@/components/viewCard";

export default {
  name: "Viewpoints",
  comment: "View a Viewpoint collection",
  components: { ViewpointForm, Card, viewCard },
  props: {
    slug: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: false,
      collection: {},
      action: "Add",
      toggleForm: false,
      record: {},
      index: 1,
      isChanged: false,
    };
  },
  mounted() {
    this.$q.loading.show();
    document.addEventListener("keydown", (e) => {
      if (e.keyCode === 37) {
        // left cursor
        // e.preventDefault()
        this.prev();
      }

      if (e.keyCode === 39) {
        // right cursor
        // e.preventDefault()
        this.next();
      }
    });

    API.get(`/viewpoints/slug/${this.slug}`).then((r) => {
      this.collection = { ...r.data.data };
      this.$q.loading.hide();
    });
  },

  computed: {
    ...mapGetters("user", { isAdmin: "isAdmin", user: "uuid" }),
  },

  methods: {
    saveOrderChange() {
      console.log("col: ", this.collection);

      API.post("/viewpoints/update-order", { ...this.collection })
        .then(() => {
          this.$q.notify({
            message: "Order changes saved",
            position: "center",
            color: "green",
          });
        })
        .catch((e) => {
          alert(e);
        });
    },

    left(item) {
      this.isChanged = true;
      let i = this.collection.vps.findIndex((x) => x.uuid === item.uuid);
      //console.log("found index: ", i, " xx: ", index)
      this.collection.vps.splice(i, 1);
      this.collection.vps.splice(i - 1, 0, item);
    },

    right(item) {
      this.isChanged = true;
      //console.log("right: ", item, index)
      let i = this.collection.vps.findIndex((x) => x.uuid === item.uuid);
      //console.log("found index: ", i, " xx: ", index)
      this.collection.vps.splice(i, 1);
      this.collection.vps.splice(i + 1, 0, item);
    },

    remove(item) {
      console.log("item :", item);
      API.post("/viewpoints/delete", {
        user: this.$store.getters["user/uuid"],
        item: item,
      }).then(() => {
        API.get(`/viewpoints/slug/${this.slug}`).then((r) => {
          this.collection = { ...r.data.data };
          this.$q.loading.hide();
        });
      });
    },

    editCard(item) {
      console.log("EDIT VIEWPOINT: ", item);
      this.action = "Edit";
      this.record = item;
      this.viewpoint = "item";
      this.toggleForm = true;
    },

    next() {
      this.index =
        this.index < this.collection.vps.length - 1
          ? this.index + 1
          : this.collection.vps.length - 1;
      this.record = this.collection.vps[this.index];
      //console.log("next: ", this.collection.vps.length, this.index);
    },

    prev() {
      this.index = this.index <= 0 ? 0 : this.index - 1;
      this.record = this.collection.vps[this.index];
      //console.log("prev: ", this.index);
    },

    viewCard(vp, index) {
      //console.log("VIEW CARD!", vp, index)
      this.record = vp;
      this.index = index;
      this.show = true;
    },
    reload(toggle = true) {
      this.$q.loading.show();
      return API.get(`/viewpoints/slug/${this.slug}`).then((r) => {
        this.collection = { ...r.data.data };
        this.toggleForm = toggle;
        this.$q.loading.hide();
      });
    },

    newline(str) {
      if (str) {
        return str.replace("\n", '<br class="newline"/>');
      }
    },

    addViewpoint() {
      this.record = {};
      this.action = "Add";
      this.toggleForm = true;
    },
  },
};
</script>
